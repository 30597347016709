<template>
  <el-select v-model="managerId"
             :placeholder="placeholder"
             :clearable="clearable"
             @change="belongerSeChange">
    <el-option v-for="item in belongerList"
               :key="item.value"
               :value="item.value"
               :label="item.title">
    </el-option>
  </el-select>
</template>

<script>
import api from '@/api/index'
import { mapState } from "vuex";
export default {
  props: {
    belongerObj: { //编辑时候回显用的
      type: Object,
      default: () => { }
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    clearable: {
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
      managerId: '',
      belongerList: [],
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  created () {
    if(this.userInfo.role == 'administrator'){
      this._initState()
    }
  },
  methods: {
    async _initState () {
      const { status_code, message } = await api.belongerListApi()
      if (status_code === 200) {
        this.belongerList = message
      }
    },
    belongerSeChange (val) {
      this.$emit('belongerSeChange', val)
    }
  }
}
</script>

<style lang="less" scoped>
</style>