<template>
  <div class="del-wrap">
    <el-dialog :title="passType"
               :visible="passDialogVisible"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="false"
               width="500px"
               center>
      <p class="content">是否通过提现请求？</p>
      <div style="display:flex;justify-content:center;margin-top:20px;"
           v-if="passType!=='经纪人通过'">
        <el-select v-model="value"
                   filterable
                   allow-create
                   clearable
                   placeholder="选择打款人"
                   style="width:40%"
                   @change="change"
                   @clear="clear">
          <el-option v-for="item in options"
                     :key="item.value"
                     :label="item.title"
                     :value="item.value"></el-option>
        </el-select>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="cancleDialog">取 消</el-button>
        <el-button type="primary"
                   @click="submitDialog">通 过</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    passDialogVisible: {
      type: Boolean,
      default: false,
    },
    passType: {
      type: String,
      default: '通过'
    }
  },
  data () {
    return {
      options: [],
      value: null,
      cache: null,
    };
  },
  created () {
    this._initState();
  },
  methods: {
    cancleDialog () {
      this.$emit("cancleDialog", "passDialogVisible");
    },
    submitDialog () {
      if (typeof this.value == "string") {
        this.options.map(item => {
          if (item.title == this.value) this.value = item.value
        })
      }
      this.$emit("submitPassDialog", this.value);
    },
    async _initState () {
      const { status_code, message } = await this.$api.reviewerOptions();
      if (status_code === 200) {
        this.options = message;
      }
    },
    // 添加打款人
    async _add (name) {
      const { status_code, message } = await this.$api.reviewerStore({ name });
      if (status_code === 200) {
        console.log(message);
      }
    },
    // 删除打款人
    async _del (id) {
      const { status_code, message } = await this.$api.reviewerDestroy({ id });
      if (status_code === 200) {
        console.log(message);
        this.$message.success(message)
        this._initState();
        this.cache = null
      }
    },
    change (res) {
      // 如果是string  说明没有对应的value 请求添加接口
      if (typeof res == "string") {
        if (res) {
          this._add(res);
          this.cache = res;
          this._initState();
        }
      } else {
        //  如果不是 缓存
        this.options.forEach((item) => {
          if (item.value == res) this.cache = item.title;
        });
      }
    },
    //删除
    clear () {
      this.options.forEach((item) => {
        if (item.title == this.cache) this._del(item.value);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  text-align: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.del-wrap /deep/ button.el-button.el-button--default {
  width: 85px;
  height: 32px;
}
</style>